<template lang="pug">
include ../../../configs/template.pug
div.row
  div(v-if="!statement.is_payed && !showRefusalReason").row.w-100.text-left
    div.col-12
      +select('body.is_payed', 'paymentStatus', 'payment', 'nameLang')(item-value="status")
    div.col-12
      FileDropZone(ref="mediaContent" v-if="checkAccess('recordBookStatement','add_file')")

  div(v-if="showRefusalReason").w-100.mt-3
    Reject

  div(v-if="!showRefusalReason").w-100.d-flex.justify-content-around.mt-3
    v-btn(
      @click="statement.is_payed ? processApplication() : changeApplication()"
      :loading="isLoading"
      color="success") {{ statement.is_payed ? $t('createRecordBook') : $t('save') }}
    v-btn(
      v-if="checkAccess('recordBookStatement', 'changeStatusToRejected', statement)"
      @click="showRefusalReason = !showRefusalReason"
      color="error") {{ $t('changeToReject') }}
</template>

<script>
import { checkAccess } from '@/mixins/permissions'
import { mapActions, mapGetters, mapState } from 'vuex'
import { SUCCESS_CODE } from '@/configs/constants'

export default {
  name: 'SailorRecordBookStatementEdit',
  props: {
    statement: { type: Object, default: () => ({}) }
  },
  components: {
    FileDropZone: () => import('@/components/atoms/DropZone/DropZone.vue'),
    Reject: () => import('./Reject.vue')
  },
  data () {
    return {
      body: {
        is_payed: false
      },
      showRefusalReason: false,
      isLoading: false,
      checkAccess
    }
  },
  computed: {
    ...mapGetters(['paymentStatusByStatus']),
    ...mapState({
      id: (state) => state.sailor.sailorId,
      nameLang: (state) => (state.main.lang === 'en' ? 'name_eng' : 'name_ukr'),
      paymentStatus: (state) => state.directory.paymentStatus
    })
  },
  mounted () {
    Object.keys(this.body).forEach((key) => (this.body[key] = this.statement[key]))
  },
  methods: {
    ...mapActions(['createServiceRecordFromStatement', 'updateRecordBookStatement', 'getRecordBookStatement']),

    /** Move sailor record book statement to service record */
    async processApplication () {
      this.isLoading = true
      const response = await this.createServiceRecordFromStatement({ ...this.$route.params })
      if (SUCCESS_CODE.includes(response.code)) this.$notification.success('AddedStatementRB')

      this.isLoading = false
    },

    /** Change payment status or set rejected status */
    async changeApplication (statusId = null) {
      const data = {
        ...this.$route.params,
        body: this.body,
        media: {
          files: this.$refs.mediaContent ? [...this.$refs.mediaContent.filesArray] : [],
          file_type: 'statement_service_record'
        }
      }
      this.isLoading = true
      const response = await this.updateRecordBookStatement(data)
      if (SUCCESS_CODE.includes(response?.code)) this.$notification.success('EditedStatementRB')
      this.isLoading = false
    }
  }
}
</script>
